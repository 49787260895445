<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px; width: 260px" @change="Search" clearable > </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="医生">
                <el-select v-model="searchForm.state" placeholder="请选择" size="small" style="margin-right: 10px; width: 150px">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="">
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="订单号/患者名称" @keyup.enter.native="Search"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
              <!-- <el-form-item>
                <download-excel v-if="DataCount" :fields="json_fields" :fetch="fetchData" worksheet="My Worksheet" :header="title" :name="`${title}_All.xls`" class="excel">
                  <el-button type="danger" size="mini" class="el-icon-download">导出</el-button>
                </download-excel>
              </el-form-item> -->
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column prop="orderNum" label="订单编号"></el-table-column>
          <el-table-column prop="patientName" label="患者" align="center"> </el-table-column>
          <el-table-column prop="diagnoseResult" label="诊断" align="center"></el-table-column>
          <el-table-column prop="doctorName" label="医生" align="center"></el-table-column>
          <el-table-column prop="bankName" label="就诊日期" align="center">
            <template slot-scope="scope">
              {{ scope.row.addTime | timefilters }}
            </template>
          </el-table-column>
          <el-table-column prop="totalMoney" label="总金额(元)" align="center">
            <template slot-scope="scope">
              {{ scope.row.totalMoney.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="toCharged(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { Outpatient } from "@/components/DrugDomain/Outpatient"
export default {
  data () {
    var outpatient = new Outpatient(this.TokenClient, this.Services.Drug)
    return {
      OutpatientDomain: outpatient,
      headers: {"Content-Type": "multipart/form-data;charset=UTF-8"},
      tableData: [],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      setDialog: false,
      DialogTitle: '添加银行卡',
      bankCardForm: {
        userName: '',
        cardNo: '',
        bankName: '',
        isDefault: false,
        oranizationId: null,
        operationUserId: null,
      },
      searchForm: {
        keyWord: '',
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      statusOption: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '待付款',
        },
        {
          value: '2',
          label: '待发货',
        },
        {
          value: '3',
          label: '已发货',
        },
        {
          value: '1',
          label: '已完成',
        },
        {
          value: '-999',
          label: '退单',
        },
        {
          value: '-1',
          label: '已取消',
        },
        {
          value: '-2',
          label: '已删除',
        },
      ],
      title: "余额明细",
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: "key",
          callback: val => {
            return "NO：" + val.key;
          },
        },
        当前余额: "name",
      },
      fileList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    toCharged(item){
      this.$router.push({
        path:'./ChargedOrRefund',
        query:{
          type:1,
          orderId:item.id
        }
      })
    },
    changePage (pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList () {
      var _this = this
      _this.tableData=[]
      var item = _this.searchForm
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00"
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59"
      } else {
        item.startTime = ""
        item.endTime = ""
      }
      _this.OutpatientDomain.DrugWaitPayMent(item.keyWord, item.startTime, item.endTime, this.pageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.pageIndex = 1
      this.getList()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
</style>
